import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '../../shared/HttpClient';
import 'rxjs/add/operator/mergeMap';
//3rd party references
import { Dialog } from 'primeng/primeng';
import { ReCaptchaComponent } from 'angular2-recaptcha/lib/captcha.component';
//Project references
import { NotificationService } from "../../shared/services/notification.service";
import { LoginViewModel } from '../Models/loginview.model';
import { LoginService } from './Services/login.service';
import * as Globalsmessages from '../../shared/globals.messages';
import * as Globals from '../../shared/globals';
import { RolesPermissionsService } from '../../shared/services/rolespermissions.service';
import { ConfigService } from '../../config/ConfigService';
import { Title } from '@angular/platform-browser';
import { SessionService } from '../../shared/services/session.service';
import { VersionCheckService } from '../../HashPolling/version-check.service';
import * as CryptoJS from 'crypto-js'; 
import { genericConfigValues, passwordEncryption, UserRoles } from '../../shared/globals';

enum AccountPasswordStatus {
    PasswordValid = <any>'0',
    PasswordExpiryWarning = <any>'1',
    PasswordExpiryInOneDay = <any>'2',
    PasswordExpired = <any>'3'
};
@Component({
    selector: 'fetchlogin-cmp',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [ReCaptchaComponent, RolesPermissionsService]
})
export class LoginComponent {
    systemEmailId: string = "";
    loading: boolean = false;
    loadingFromProxy: boolean = false;
    //Variable declarations
    loginDetail: any = {};
    public message: string = null;
    showChangePasswordRedirect: boolean = false;
    display: boolean = false;
    displayTnC: boolean = false;
    expiryMessage: string;
    pwdExpired: boolean = false;
    expiryDays: number;
    readTandC: string;
    acceptedTnC: string;
    header1: string = '';
    header2: string = '';
    headerClass: string;
    lastLoggedIn: Date;
    public showCaptcha: boolean = false;
    captchaValid: boolean = false;
    landinglogo: string = '';
    baseUrl: string = '';
    captchaSiteKey: string = '';
    key : any;  
    iv : any;
    applyEncription: boolean;
    passwordResetMsg:string;
    displayPReSet:boolean=false;
    showPwdExpWarningDialog() {
        this.display = true;
    }

    routeToLanding() {
        this.display = false;
        if (this.pwdExpired) {
            this.router.navigate(['/changepassword']);
            return;
        }
        else if (this.readTandC === 'false') {
            this.router.navigate(['/home/dashboard']);
        } else {
            this.router.navigate(['/termsandcondition']);
        }
    }

    //Const logic
    constructor(
        private titleService: Title,
        private router: Router,
        private activateRoute: ActivatedRoute,
        private loginService: LoginService,
        private activatedRoute: ActivatedRoute,
        private notification: NotificationService,
        public reCaptchaComponent: ReCaptchaComponent,
        private rolesPermissionsService: RolesPermissionsService,
        private config: ConfigService,
        private sessionService: SessionService,
        private versionCheckService : VersionCheckService) {
        this.activatedRoute.queryParams.subscribe(data => this.acceptedTnC = data['acceptedTnC']);
        this.header1 = config.getUiConfiguration('LogincomponentHeader');
        this.header2 = config.getUiConfiguration('LogincomponentHeader2');
        this.landinglogo = config.getUiConfiguration("LandingPageLogo");
        this.systemEmailId = genericConfigValues.systemAdminEmailId;;
        this.key = CryptoJS.enc.Utf8.parse(passwordEncryption.passwordEncryptionKey);
        this.iv = CryptoJS.enc.Utf8.parse(passwordEncryption.passwordEncryptionIV);
        this.applyEncription = passwordEncryption.applyEncription;
        reCaptchaComponent.reset();
    }

    //Init logic
    ngOnInit() {
        this.titleService.setTitle("Fetch - Login");
        // reset login status
        this.loginService.logout();
        this.captchaSiteKey = genericConfigValues.captchaSiteKey;
        if (this.acceptedTnC === "false") {
            this.showTnCDialog();
        }


        //this is added to accomodate proxy login page
        if (this.sessionService.getItem('uname') != null && this.sessionService.getItem('upwd') != null) {
            this.loadingFromProxy = true;
            this.loginDetail.UserName = this.sessionService.getItem('uname');
            this.loginDetail.Password = this.sessionService.getItem('upwd');
            this.sessionService.removeItem('uname');
            this.sessionService.removeItem('upwd');
            this.loginSubmit();

        }
        //


        /*
        if (location.origin != null) {
            this.baseUrl = location.protocol + "//" + location.host;
        }
        */
        let token = this.sessionService.getItem('id_token');

        this.baseUrl = location.protocol + "//" + location.host + location.pathname;
        var url = this.baseUrl+'/assets/version.json';
        this.versionCheckService.initVersionCheck(url, true);
    }
    //Login click event
    loginSubmit(): void {
        //This code added to support proxy login page

        if (this.showCaptcha && this.captchaValid === false) {
            this.notification.showError(Globalsmessages.errCaptchaRequired[0], Globalsmessages.errCaptchaRequired[1]);
            return;
        }

        if (this.loadingFromProxy !== true) {
            this.loading = true;
            //this.loadingFromProxy = false;
        }

        var result: any;
        var errorResponse: any;
        var errorDesc: any;
        const criticalAlertEx = this.activateRoute.snapshot.queryParams['criticalAlertEx'];
        const toxicityAlertEx = this.activateRoute.snapshot.queryParams['toxicityAlertEx'];
        this.loginService.getAuthToken(this.getCredentialsPayLoad(), this.getAuthorizationHeader())
            .subscribe((data) => {
                result = data;
                
               if (data && data.access_token) {
                    Globals.setTokenKey('Bearer ' + data.access_token);
                    this.sessionService.setItem('id_token', 'Bearer ' + data.access_token);
                    this.sessionService.setItem('loggedInUserId', data.userID);
                    this.sessionService.setItem('isLoggedIn', 'true');
                    this.sessionService.setItem('lastLoggedIn', data.lastLoggedIn);
                    this.sessionService.setItem('userPermissions', data.userPermissions);
                    this.sessionService.setItem('isSuperUser', data.isSuperUser);
                    this.sessionService.setItem('userSponsorId', data.userSponsorId);
                    this.sessionService.setItem('IsUserKitPackAdmin', data.roleId.toUpperCase() === UserRoles.KitPackAdmin.toUpperCase());
                    this.sessionService.setItem('isExternal', data.isExternal);
                    this.sessionService.setItem('roleId', data.roleId);
                    Globals.authUser.userName = data.userName;
                    Globals.authUser.isLoggedIn = true;
                    this.expiryDays = data.passwordExpiryDays;
                    var acctPwdStatus = data.PassWordExpiryStatus;
                    this.readTandC = data.readTermsCondition;
                    if(data && data.isPassword15Char== "False")
                        {
                            this.passwordResetMsg="Eurofins password policy has been changed, please reset your password to login''. Click 'Ok' to navigate to password reset page.";
                            this.displayPReSet=true; 
                            this.loading=false;    
                            return;    
                        }
                    switch (acctPwdStatus) {
                        case AccountPasswordStatus.PasswordValid:
                            if (data.readTermsCondition === "true") {
                                this.router.navigate(['/termsandcondition']);
                                break;
                            } else if(criticalAlertEx === '78BDA289-AEBA-48BC-8690-C0FA3861DCDB'){
                                this.router.navigate(['/home/queryToolExtUser/QueryManagement/ManageAlerts'], { queryParams: {token: '173C2EAD-E2F2-429B-ABE1-32EE27F35956'}});
                                //this.router.navigate(['/home/queryToolExtUser/QueryManagement/ManageAlerts']);
                                break;
                            } else if(toxicityAlertEx === '3AD90B02-72FA-4097-ADDF-6F57216D67F6'){
                                this.router.navigate(['/home/queryToolExtUser/QueryManagement/ManageAlerts'], { queryParams: {toxicityToken: '443CF603-4BB4-4BA0-8D05-C6750597136F'}});
                                //this.router.navigate(['/home/queryToolExtUser/QueryManagement/ManageAlerts']);
                                break;
                            }else {
                                this.router.navigate(['/home/dashboard']);
                                break;
                            }
                        case AccountPasswordStatus.PasswordExpiryWarning:
                            this.expiryMessage = 'Your password would expire in ' + data.passwordExpiryDays + ' Days. Please change your password soon.';
                            this.loading = false;
                            this.showPwdExpWarningDialog();
                            break;
                        case AccountPasswordStatus.PasswordExpiryInOneDay:
                            this.pwdExpired = true;
                            this.expiryMessage = Globalsmessages.warnPasswordExpiry1Day[0];
                            this.loading = false;
                            this.showPwdExpWarningDialog();
                            break;
                        case AccountPasswordStatus.PasswordExpired:
                            this.pwdExpired = true;
                            this.expiryMessage = Globalsmessages.warnPasswordExpired[0];
                            this.loading = false;
                            this.showPwdExpWarningDialog();
                            break;
                        default:
                            this.notification.showError(Globalsmessages.errSystemException[0] + this.systemEmailId, Globalsmessages.errSystemException[1]);
                            this.router.navigate(['']);
                            break;
                    }
                    
                } else {
                    this.notification.showError(Globalsmessages.errUserDisabled[0], Globalsmessages.errUserDisabled[1]);
                    this.router.navigate(['']);
                }
            },
                (err) => {
                    this.loading = false;
                    this.loadingFromProxy = false;
                    errorResponse = err.json();
                    if (errorResponse.error_description == null) {
                        this.notification.showError(Globalsmessages.errSystemException[0] + this.systemEmailId, Globalsmessages.errSystemException[1]);
                        this.router.navigate(['']);
                        return;
                    }
                    errorDesc = JSON.parse(errorResponse.error_description);

                    if (errorResponse.error === 'InValidLogin') {
                        this.notification.showError(Globalsmessages.errInvalidUser[0],Globalsmessages.errInvalidUser[1] );
                        this.showCaptcha = true;
                        this.captchaValid = false;
                    
                    if (this.showCaptcha) {
                        this.resetCaptcha();
                    }
                        //this.router.navigate(['']);
                        return;
                    }
                    if (errorResponse.error === 'UserNotAllowedToSite') {
                        this.notification.showError(Globalsmessages.siteAccessNotAllowed[0] + this.systemEmailId, Globalsmessages.siteAccessNotAllowed[1]);
                        this.router.navigate(['']);
                        return;
                    }
                    else if (errorResponse.error === 'DisabledUser') {
                        this.notification.showError(Globalsmessages.errInvalidCredentials[0] + this.systemEmailId, Globalsmessages.errInvalidCredentials[1]);
                        this.router.navigate(['']);
                        return;
                    } else if (errorResponse.error === 'InActiveUser') {
                        this.notification.showError(Globalsmessages.errInActiveUser[0] + this.systemEmailId, Globalsmessages.errInActiveUser[1]);
                        this.router.navigate(['']);
                        return;
                    } else if (errorResponse.error === 'NotRegistered') {
                        this.notification.showError(Globalsmessages.errUserNotRegistered[0], Globalsmessages.errUserNotRegistered[1]);
                        this.router.navigate(['']);
                        return;
                    } else if (errorResponse.error === 'SiteAccessNotAllowed') {
                        this.notification.showError(Globalsmessages.siteAccessNotAllowed[0], Globalsmessages.siteAccessNotAllowed[1]);
                        this.router.navigate(['']);
                        return;
                    }
                    else if (errorResponse.error === 'InValidUser') {
                        if (errorDesc.PassWordRetryAttempts == '2' || errorDesc.PassWordRetryAttempts == '-1') { //-1 indicates login name does not exist
                            this.notification.showError(Globalsmessages.errUserDisabled[0], Globalsmessages.errUserDisabled[1]);
                            this.showCaptcha = true;
                            this.captchaValid = false;
                        }
                        if (this.showCaptcha) {
                            this.resetCaptcha();
                        }
                        this.notification.showError(Globalsmessages.errUserDisabled[0], Globalsmessages.errUserDisabled[1]);
                        return;
                    }
                    else if (errorResponse.error === 'ActiveDirExpired') {
                        this.notification.showError(Globalsmessages.errUserActiveDirExp[0], Globalsmessages.errUserActiveDirExp[1]);
                        this.router.navigate(['']);
                        return;
                    }
                    else {
                        this.notification.showError(Globalsmessages.errSystemException[0] + this.systemEmailId,
                            Globalsmessages.errSystemException[1]);
                        this.router.navigate(['']);
                        return;
                    }
                });

    }

    //reCaptcha response event//
    handleCorrectCaptcha(event: any) {
        this.loginService.validateRecaptcha(event).subscribe( (res)=> {this.saveResponse(res)}, (err) => this.notification.showError("An Error Occoured","Error!"));
        
    }
    saveResponse(resp:any){
        if(!resp.isValid ){
            this.resetCaptcha();
        }
        this.captchaValid = resp.isValid;
        
    }

    //rest the captcha component//
    resetCaptcha() {
        let myWindow: any = window;
        myWindow.grecaptcha.reset();
    }

    //Private Helpers//
    getAuthorizationHeader(): Headers {
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return headers;
    }

    //Get the credentials.
    getCredentialsPayLoad(): string {
        var encryptedpassword= null;
        if(this.applyEncription){
            encryptedpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this.loginDetail.Password), this.key,  
            {  
                keySize: 128 / 8,  
                iv: this.iv,  
                mode: CryptoJS.mode.CBC,  
                padding: CryptoJS.pad.Pkcs7  
            }); 
        }else{
            encryptedpassword = encodeURIComponent(this.loginDetail.Password);
        }
        
        var credentials = 'grant_type=password' +
            '&UserName=' + this.loginDetail.UserName +
            '&Password=' + encryptedpassword +
            '&Ticket=' + new Date().getTime();
        return credentials;
    }

    // Set the flag to display terms and conditions dialog.
    showTnCDialog() {
        this.displayTnC = true;
    }

    // Logs out the user from the system.
    logout() {
        this.displayTnC = false;
        this.sessionService.clearAll();
        this.router.navigate(['']);
    }
    routeToChangePassword()
    {
        this.router.navigate(['/changepassword']);
        return;
    }

}
