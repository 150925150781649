﻿import { Injectable } from '@angular/core';
import { LoginViewModel } from '../../Models/loginview.model';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import * as  Globals from '../../../shared/globals';
import { HttpClient } from '../../../shared/HttpClient';
import { ConfigService } from '../../../config/ConfigService';
import { SessionService } from '../../../shared/services/session.service';
import { AppState } from '../../../EComPortal/ecommportal.reducer'
import * as collection from '../../../EComPortal/actions/collection.actions'
import { ClearSiteState } from '../../../EComPortal/actions/updateStudySite.actions'
import { Store } from '@ngrx/store';
import { getCartItems } from '../../../EComPortal/reducers/selector';
import { LocalStorage } from '@ng-idle/core';
import { genericConfigValues } from '../../../shared/globals';

@Injectable()
export class LoginService {
    http: Http;
    httpClient: HttpClient;
    baseUrl;
    authorizationServiceUrl;
    cartitems;
    isClientPortal: boolean = false;
    constructor(http: Http, httpClient: HttpClient, config: ConfigService, private sessionService: SessionService, private store: Store<AppState>, private localStorage: LocalStorage) {
        this.http = http;
        this.httpClient = httpClient;
        this.baseUrl = config.get('apiUrl');
        this.isClientPortal = genericConfigValues.clientPortal;
        this.authorizationServiceUrl = config.get('authorizationServiceUrl');
    }

    public logout() {
        this.store.select(getCartItems).subscribe(res => {
            this.cartitems = res.map(i => i.key);
        });
        this.store.dispatch(new collection.RemoveDbItems(this.cartitems));
        this.store.dispatch(new ClearSiteState());
        if (this.isClientPortal) {
            localStorage.removeItem('id_token');
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('lastLoggedIn');
            localStorage.removeItem('loginTimeRecorded');
            //remove default parcel data
            localStorage.removeItem('setParcelDefaults');
        }
        else {
            this.sessionService.removeItem('id_token');
            this.sessionService.removeItem('isLoggedIn');
            this.sessionService.removeItem('lastLoggedIn');
            this.sessionService.removeItem('loginTimeRecorded');
            //remove default parcel data
            this.sessionService.removeItem('setParcelDefaults');
        }
        Globals.setTokenKey('Not-Set');
        Globals.authUser.isLoggedIn = false;
        this.localStorage.removeItem('studiesList')
    }

    // -- GET METHODS -- //
    public getTestData() {
        this.httpClient.get(this.baseUrl + 'api/User/GetTestData').subscribe(
            () => console.log('Request Complete')
        );
    }

    // -- POST,PUT,DELETE METHODS --//
    public getAuthToken(data: string, headers: Headers) {
        return this.http.post(this.authorizationServiceUrl + 'token', data, { headers: headers })
            .map(res => {
                if (res.status < 200 || res.status >= 300) {
                    throw new Error('This request has failed ' + res.status);
                } else {
                    return res.json();
                }
            });
        //}).catch(this.handleError);
    }

    public isUserLoggedIn(): boolean {
        if (this.sessionService.getItem('id_token') !== 'Not-Set' && this.sessionService.getItem('isLoggedIn') === 'true') {
            return true;
        } else {
            return false;
        }
    }

    //http error handling
    private handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return Observable.throw(errMsg);
    }

    public getLoginUserRole(): any {
        return this.sessionService.getItem('roleId');
    }

    public isUserExternal(): any {
        return this.sessionService.getItem('isExternal');
    }

    public GetAntiForgeryToken() {
        return this.httpClient.generateAntiForgeryToken();
    }
    validateRecaptcha(resp: any): any {
        return this.httpClient.post('api/Login/validateRecaptcha', JSON.stringify(resp)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
}

